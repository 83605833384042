import React from 'react';
//import Amplify, { Interactions } from 'aws-amplify';
//import { ChatBot, AmplifyTheme } from 'aws-amplify-react';
import { ChatBot } from 'aws-amplify-react';

const Chatbox = () => {

    return (
        <>
            <div style={{ flex: "5", padding: "10px", marginLeft: "270px", marginBottom: "40px", minHeight: "100vh" }}>
                <p>BbftMainMenu Bot</p>
                <ChatBot
                    title="HHCC Test Bot"
                    botName="hhcctestbot_staging"
                    welcomeMessage="Thanks for calling. How can I help you today?"
                />
            </div>
        </>
    );
}

export default Chatbox;