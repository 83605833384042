import React from 'react';

const WinAmp = () => {

    return (
        <>
            <div style={{ flex: "5", padding: "10px", marginLeft: "270px", marginBottom: "40px", minHeight: "100vh" }}>
                <div id="app"></div>
                <script src="https://unpkg.com/webamp"></script>
                <script>new Webamp().renderWhenReady(app);</script>
            </div>
        </>
    );
}

export default WinAmp;