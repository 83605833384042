import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { FiHome, FiArrowLeftCircle, FiArrowRightCircle, FiHelpCircle } from "react-icons/fi";
import { FaList, FaGem } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BiCog, BiCodeCurly } from "react-icons/bi";
import 'react-pro-sidebar/dist/css/styles.css';
import './Header.css';
import sidebarBg from '../assets/bg1.jpg'

const Header = () => {
    const [menuCollapse, setMenuCollapse] = useState(false)

    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    return (
        <>
            <div id="header">
                <ProSidebar
                    image={sidebarBg}
                    collapsed={menuCollapse}>
                    <SidebarHeader>
                        <div className="logotext">
                            <p>{menuCollapse ? "IVR" : "IVR.dev"}<Link to="/" /></p>
                        </div>
                        <div className="closemenu" onClick={menuIconClick}>
                            {menuCollapse ? (<FiArrowRightCircle />) : (<FiArrowLeftCircle />)}
                        </div>
                    </SidebarHeader>
                    <SidebarContent>
                        <Menu iconShape="circle">
                            <MenuItem active={true} icon={<FiHome />}>Home<Link to="/" /></MenuItem>
                            <SubMenu title="BotTester" icon={<FaList />}>
                                <MenuItem icon={<FaGem />}>HHCCMainMenu</MenuItem>
                                <MenuItem icon={<FaGem />}>YesOrNo</MenuItem>
                                <MenuItem icon={<FaGem />}>CollectHonorsNumber</MenuItem>
                                <MenuItem icon={<FaGem />}>BbftMainMenu<Link to="/bot/BbftMainMenu" /></MenuItem>
                                <MenuItem icon={<FaGem />}>WinAmp<Link to="/winamp" /></MenuItem>
                            </SubMenu>
                            <MenuItem icon={<BiCodeCurly />}>Connect Flow Tools<Link to="/connect-flow-tools" /></MenuItem>
                            <MenuItem icon={<FiHelpCircle />}>Help & FAQs<Link to="/help" /></MenuItem>
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter>
                        <Menu iconShape="circle">
                            <MenuItem icon={<BiCog />}>Settings<Link to="/settings" /></MenuItem>
                        </Menu>
                    </SidebarFooter>
                </ProSidebar>
            </div>
        </>
    );
}

export default Header;