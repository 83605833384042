/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:def60b25-91da-4c28-9d43-5129bfc64607",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lqZSP6XgR",
    "aws_user_pools_web_client_id": "7u43enk5eefju3agr2oj58b8ae",
    "oauth": {},
    "aws_bots": "enable",
    "aws_bots_config": [
        {
            "name": "hhcctestbot_master",
            "alias": "$LATEST",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
