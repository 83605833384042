import React, { useState } from 'react';
//import './App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Header from './Components/Header';
import ChatBox from './Components/Chatbox';
import WinAmp from './Components/WinAmp';


const App = (props) => {


  const [menuCollapse] = useState(false);

  return (
    <>
      <Router>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path='/'><Main /></Route>
            <Route path='/settings'><Settings menuCollapse={menuCollapse} /></Route>
            <Route path='/bot/BbftMainMenu'><ChatBox /></Route>
            <Route path='/winamp'><WinAmp /></Route>
          </Switch>
        </div>
      </Router>
    </>
  );
}

function Settings(props) {
  //const [menuCollapse, setMenuCollapse] = useState(props)
  return (
    <div style={{ flex: "5", padding: "10px", marginLeft: props.menuCollapse ? "270px" : "270px" }}>
      <main>
        <header>
          <h1>Settings</h1>
        </header>
      </main>
      <Footer />
    </div>
  )
}

function Main() {
  return (
    <div style={{ flex: "5", padding: "10px", marginLeft: "270px", marginBottom: "40px", minHeight: "100vh" }}>
      <main>
        <header>
          <h1>Home</h1>
        </header>
      </main>
      <Footer />
    </div>
  )
}

function Footer() {
  return (
    <footer style={{ position: "absolute", bottom: "0", height: "2.5rem", borderBottom: "1px solid #efefef" }}>
      <AmplifySignOut />
      <small>Footer</small>
    </footer>
  )
}

export default withAuthenticator(App);
